<template>
  <!-- 所有货主 -->
  <div>
    <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="UserLoginPhone" label="注册ID" width="120"></el-table-column>
      <el-table-column align="center" prop="InviteCode" label="邀请码" width="120"></el-table-column>
      <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="250" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="LegalPersonIName" label="姓名" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="LegalPersonlPhone" label="手机号码" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="160" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="BaseStatus" label="审核状态" width="110" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.BaseStatus == '审核通过'" type="success">{{ scope.row.BaseStatus }}</el-tag>
          <el-tag v-else type="warning">{{ scope.row.BaseStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="DriverCntCertified" label="已认证司机数" width="100"></el-table-column>
      <el-table-column align="center" prop="DriverCnt" label="未认证司机数" width="100">
        <template slot-scope="scope">
          <div style="color: #ff2323">{{ scope.row.DriverCnt }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="VehicleCntCertified" label="已认证车辆数" width="100"></el-table-column>
      <el-table-column align="center" prop="VehicleCnt" label="未认证车辆数" width="100">
        <template slot-scope="scope">
          <div style="color: #ff2323">{{ scope.row.VehicleCnt }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="IsMultiterminal" label="是否启动多用户登录" width="260">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.IsMultiterminal" inactive-text="否" active-text="是"
            @change="changeIsMultiterminal(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="260">
        <template slot-scope="scope">
          <el-button type="text" @click="goFacility(scope.row, '/platform/alluser/ownerInfo')">详情</el-button>
          <el-button type="text" @click="goFacility(scope.row, '/platform/alluser/pact')">合同</el-button>
          <el-button type="text" @click="examineRate(scope.row)">费率</el-button>
          <el-button type="text" @click="approveType(scope.row)">认证状态
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看费率 -->
    <el-dialog :visible.sync="flag.rateMdel" width="50%">
      <span slot="title">查看费率</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>货主名称：</strong><u>{{ ownerInfo.EnterpriseFullName }}</u><br />
        </div>
        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading1"
          max-height="500px">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} /
                {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span>
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="费率" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.TecRate ? scope.row.TecRate + '%' : scope.row.TecRate }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="toDetail(scope.row)"
                :disabled="!scope.row.RateID">查看历史记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 查看费率历史记录 -->
    <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
      <div style="text-align: center;font-weight: bold;margin-bottom: 10px;">发票-业务-场景： <span
          v-if="currentItem.TaskSceneName">
          {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} / {{
            currentItem.TaskSceneName
          }}</span>
        <span v-else>
          {{ currentItem.InvoiceTypeName }}</span>
      </div>
      <el-table :data="rateDataRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading1">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.Rate ? scope.row.Rate + '%' : '' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.TecRate ? scope.row.TecRate + '%' : '' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px">
        <el-pagination background class="pagination" @current-change="handleCurrentChangeRecord"
          :current-page.sync="paginationRecord.page" :page-size="paginationRecord.pagesize"
          layout="total, prev, pager, next, jumper" :total="paginationRecord.total"></el-pagination>
      </div>
    </el-dialog>
    <!-- 认证状态 -->
    <el-dialog :visible.sync="flag.approveMdel" width="700px">
      <span slot="title">认证状态</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>货主名称：</strong><u>{{ ownerInfo.EnterpriseFullName }}</u><br />
        </div>
        <approvelist :sysConfigList="sysConfigList"></approvelist>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetThirdStatus } from "@/api/platform/ownerPricing/index"
import {
  GetOwnerRate,
  GetRateRecords
} from "@/api/newRate";
import approvelist from "@/components/businessCmpt/approvelist";
import { EditMultiterminal } from "@/api/common/common";
export default {
  props: ["tableData", "loading", "pageIndex"],
  data() {
    return {
      // 弹窗集合
      flag: {
        // 查看费率
        rateMdel: false,
        // 认证状态
        approveMdel: false
      },
      // 货主信息
      ownerInfo: {},
      // 费率信息
      rateData: [],
      // 认证状态
      sysConfigList: [],
      currentItem: {},
      rateRecordDialog: false, // 历史记录弹窗
      rateDataRecord: [],
      // 分页参数
      paginationRecord: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading1: false
    };
  },
  methods: {
    // 切换是否多用户登录
    changeIsMultiterminal(item) {
      EditMultiterminal({
        isMultiterminal: item.IsMultiterminal,
        userId: item.UserID
      }).then(res => {
        this.$message.success("设置成功")
      })
    },
    // 认证状态
    approveType(item) {
      this.ownerInfo = item
      GetThirdStatus({ UserID: item.UserID }).then(res => {
        this.sysConfigList = res.sysConfigList
        this.flag.approveMdel = true;
      })
    },
    // 查看费率
    examineRate(item) {
      this.ownerInfo = item
      GetOwnerRate({ userid: item.UserID }).then((res) => {
        if (res.data.length == 0) {
          this.$message.error("该货主暂未设置费率");
          return;
        } else {
          let list = [];
          res.data.forEach(item => {
            item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
            item.TecRate = (item.TecRate == 0 || item.TecRate == null) ? '' : item.TecRate
            list.push(item)
          })
          this.rateData = list;
        }
        this.flag.rateMdel = true;
      });
    },
    // 查看详情
    goFacility(item, url) {
      this.$router.push({
        path: url,
        query: {
          UserID: item.UserID,
          pageType: 'owner',
          companyName: item.EnterpriseFullName,
          urlType: 'second',
          pageIndex: this.pageIndex
        },
      });
    },
    // 查看历史记录
    toDetail(item) {
      this.currentItem = item
      this.getRateChangeRecord()
    },
    //分页控件页码change事件回调
    handleCurrentChangeRecord(e) {
      this.paginationRecord.page = e;
      this.getRateChangeRecord();
    },

    // 获取历史费率
    getRateChangeRecord() {
      this.loading1 = true;
      let data = {
        pageIndex: this.paginationRecord.page,
        PageSize: this.paginationRecord.pagesize,
        rateID: this.currentItem.RateID
      };
      GetRateRecords(data)
        .then((res) => {
          this.rateDataRecord = res.data.DataList || [];
          this.paginationRecord.total = Number(res.data.TotalCount);
          this.rateRecordDialog = true
        })
        .finally(() => {
          this.loading1 = false;
        });
    },
  },
  components: {
    approvelist
  }
};
</script>

<style lang="scss" scoped>
.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>